import React, { useState } from 'react';

import LoginBackground from "../assets/images/loginBackground.webp"
import "../assets/styles/UIComponents.css"

import SignIn from '../components/SignIn';
import SignUp from '../components/SignUp';
import ForgotPassword from '../components/ForgotPassword';


function Home() {
  const [showSignIn, setShowSignIn] = useState(true);
  const [showSignUp, setShowSignUp] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  function clickSignIn(){
    setShowSignIn(true);
    setShowSignUp(false);
    setShowForgotPassword(false)
  }

  function clickSignUp(){
    setShowSignIn(false);
    setShowSignUp(true);
    setShowForgotPassword(false)
  }

  function clickForgotPassword(){
    setShowSignIn(false);
    setShowSignUp(false);
    setShowForgotPassword(true)
  }

  return (
    <div>
      <img style={{position:"fixed", top:"0px", left:"0px", minWidth:"100%", minHeight:"100%", opacity:"0.3"}} src={LoginBackground} alt=""></img>
      <div style={{display: "flex", justifyContent: "center", top:"25vh", position:"relative"}}>
        {showSignIn && 
          <SignIn 
            clickSignUp={clickSignUp}
            clickForgotPassword={clickForgotPassword}/>
        }
        {showSignUp && <SignUp clickSignIn={clickSignIn}/>}
        {showForgotPassword && <ForgotPassword clickSignIn={clickSignIn}/>}
      </div>
    </div>
  );
}

export default Home;