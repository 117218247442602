// import React, { useState, useEffect } from 'react';

import React, { useState } from 'react';

// import axios from "axios";
import Buttons from './Buttons';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import "../assets/styles/UIComponents.css"
import TransparentLogo from "../assets/images/logo-no-background.png"



function SignUp(props) {

  const [signUpLoadingState, setSignUpLoadingState] = useState(false);
  const [userNameError, setUserNameError] = useState(false);
  const [userNameErrorMessage, setUserNameErrorMessage] = useState("Server Error");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("Server Error");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("Server Error");
  const [rePasswordError, setRePasswordError] = useState(false);
  const [rePasswordErrorMessage, setRePasswordErrorMessage] = useState("Server Error");
  
  
  function signUp(){
    setSignUpLoadingState(true)
    
    setUserNameError(!userNameError)
    setUserNameErrorMessage("Username error")
    
    setEmailError(!emailError)
    setEmailErrorMessage("Email error")
    
    setPasswordError(!passwordError)
    setPasswordErrorMessage("Password error")
    
    setRePasswordError(!rePasswordError)
    setRePasswordErrorMessage("Re Type Password error")
    
    setSignUpLoadingState(false)
  }

  return (
    <div className="agsFloatingBoxes" style={{width:"50vw", display: "grid", justifyItems: "center", alignItems: "center"}}>
      <img src={TransparentLogo} alt="logo" style={{width: "250px", marginTop:"10px"}}/>
      <h3 style={{fontFamily:"Exo2-Bold", marginTop:"50px"}}>Create your new AGS account</h3>
      <Grid container spacing={2} style={{display: "grid", justifyItems: "center", alignItems: "center"}}>
        <Grid item xs={12} style={{position:"relative"}}>
            <TextField
                required
                label="Username"
                size="small"
                InputProps={{ style: { fontFamily:"Exo2-Bold" } }}
                InputLabelProps={{ style: { fontFamily:"Exo2-Bold" } }}
            />
            {userNameError && <div className='agsErrorText'>{userNameErrorMessage}</div>}
        </Grid>
        <Grid item xs={12} style={{position:"relative", marginTop:"5px"}}>
            <TextField
                required
                label="Email"
                size="small"
                InputProps={{ style: { fontFamily:"Exo2-Bold" } }}
                InputLabelProps={{ style: { fontFamily:"Exo2-Bold" } }}
            />
            {emailError && <div className='agsErrorText'>{emailErrorMessage}</div>}
        </Grid>
        <Grid item xs={12} style={{position:"relative", marginTop:"5px"}}>
            <TextField
                label="Password"
                type="password"
                size="small"
                InputProps={{ style: { fontFamily:"Exo2-Bold" } }}
                InputLabelProps={{ style: { fontFamily:"Exo2-Bold" } }}
            />
            {passwordError && <div className='agsErrorText'>{passwordErrorMessage}</div>}
        </Grid>
        <Grid item xs={12} style={{position:"relative", marginTop:"5px"}}>
            <TextField
                label="Retype Password"
                type="password"
                size="small"
                InputProps={{ style: { fontFamily:"Exo2-Bold" } }}
                InputLabelProps={{ style: { fontFamily:"Exo2-Bold" } }}
            />
            {rePasswordError && <div className='agsErrorText'>{rePasswordErrorMessage}</div>}
        </Grid>
        <Grid item xs={12} style={{position:"relative", marginTop:"5px"}}>
          <Buttons 
            btnText={"Sign Up"}
            loadingState={signUpLoadingState}
            buttonClick={signUp}
          />
        </Grid>
        <Grid item xs={12} style={{fontFamily:"Exo2-Bold", display: "grid", justifyItems: "center", alignItems: "center"}}>
          <div className="agslinkText" onClick={() => props.clickSignIn()}>Back to SignIn</div>
        </Grid>
      </Grid>
    </div>
  );
}

export default SignUp;