import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

// import axios from "axios";
import Buttons from './Buttons';
import "../assets/styles/UIComponents.css"
import TransparentLogo from "../assets/images/logo-no-background.png"



function ForgotPassword(props) {

  const [nextLoadingState, setNextLoadingState] = useState(false);
  const [emailUserNameError, setEmailUserNameError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Server Error");

  function clickNext(){
    setNextLoadingState(true)
    setEmailUserNameError(true)
    setErrorMessage("Sample Error")
    setNextLoadingState(false)
  }

  return (
    <div className="agsFloatingBoxes" style={{width:"50vw", display: "grid", justifyItems: "center", alignItems: "center"}}>
      <img src={TransparentLogo} alt="logo" style={{width: "250px", marginTop:"10px"}}/>
      <h3 style={{fontFamily:"Exo2-Bold", marginTop:"50px"}}>Forgot Password</h3>
      <Grid container spacing={2} style={{display: "grid", justifyItems: "center", alignItems: "center"}}>
        <Grid item xs={12} style={{position:"relative"}}>
            <TextField
                required
                label="Email or Username"
                size="small"
                InputProps={{ style: { fontFamily:"Exo2-Bold" } }}
                InputLabelProps={{ style: { fontFamily:"Exo2-Bold" } }}
            />
            {emailUserNameError && <div className='agsErrorText'>{errorMessage}</div>}
        </Grid>
        <Grid item xs={12} style={{position:"relative", marginTop:"5px"}}>
          <Buttons 
            btnText={"Next"}
            loadingState={nextLoadingState}
            buttonClick={clickNext}
          />
        </Grid>
        <Grid item xs={12} style={{fontFamily:"Exo2-Bold", display: "grid", justifyItems: "center", alignItems: "center"}}>
          <div className="agslinkText" onClick={() => props.clickSignIn()}>Back to SignIn</div>
        </Grid>
      </Grid>
    </div>
  );
}

export default ForgotPassword;