import React, { Component } from 'react';
import { Link } from "react-router-dom";

class AboutUs extends Component {
  render() {
    return (
      <>
        <h1>About Us</h1>
        <nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/pagenotfound">Not Found</Link>
            </li>
          </ul>
        </nav>
      </>
    );
  }
}

export default AboutUs;