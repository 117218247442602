// import React, { useState, useEffect } from 'react';

import React, { useState } from 'react';

// import axios from "axios";
import Buttons from './Buttons';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import "../assets/styles/UIComponents.css"
import TransparentLogo from "../assets/images/logo-no-background.png"


function SignIn(props) {

  // const [location, setLocation] = useState("NA");
  // const [wind_direction, setWind_direction] = useState("NA");
  // const [getDetailsLoading, setGetDetailsLoading] = useState(false);
  // const [clearDetailsLoading, setClearDetailsLoading] = useState(false);

  // function getTemperature(){
  //   setGetDetailsLoading(true);
  //   var url = "https://flexweather.com/api/now?lat=52.5200&lon=13.4050&units=metric"
  //   axios.get(url)
  //   .then(function (response) {
  //     console.log(response);
  //     setCondition(response.data.condition)
  //     setLocation(response.data.location)
  //     setWind_direction(response.data.wind_direction)
  //     setGetDetailsLoading(false)
  //   })
  //   .catch(function (error) {
  //     console.log(error);
  //     setGetDetailsLoading(false)
  //   });
  // }
  
  // function clearData(){
  //   setClearDetailsLoading(true)
  //   setCondition("NA")
  //   setLocation("NA")
  //   setWind_direction("NA")
  //   setClearDetailsLoading(false)
  // }
  const [userNamePasswordError, setUserNamePasswordError] = useState(false);
  const [userNamePasswordErrorMessage, setUserNamePasswordErrorMessage] = useState("Server Error");
  const [logInLoadingState, setLogInLoadingState] = useState(false);

  function logIn(){
    setLogInLoadingState(true)
    setUserNamePasswordError(true)
    setUserNamePasswordErrorMessage("Username or Password error")
    setLogInLoadingState(false)
  }

  return (
    <div className="agsFloatingBoxes" style={{width:"50vw", display: "grid", justifyItems: "center", alignItems: "center"}}>
      <img src={TransparentLogo} alt="logo" style={{width: "250px", marginTop:"10px"}}/>
      <h3 style={{fontFamily:"Exo2-Bold", marginTop:"50px"}}>Log in to your AGS account</h3>
      <Grid container spacing={2} style={{display: "grid", justifyItems: "center", alignItems: "center"}}>
        <Grid item xs={12}>
            <TextField
                required
                id="outlined-required"
                label="Username"
                size="small"
                InputProps={{ style: { fontFamily:"Exo2-Bold" } }}
                InputLabelProps={{ style: { fontFamily:"Exo2-Bold" } }}
            />
        </Grid>
        <Grid item xs={12} style={{position:"relative"}}>
            <TextField
                id="outlined-password-input"
                label="Password"
                type="password"
                size="small"
                InputProps={{ style: { fontFamily:"Exo2-Bold" } }}
                InputLabelProps={{ style: { fontFamily:"Exo2-Bold" } }}
            />
            {userNamePasswordError && <div className='agsErrorText'>{userNamePasswordErrorMessage}</div>}
        </Grid>
        <Grid item xs={12} style={{position:"relative", marginTop:"5px"}}>
          <Buttons 
            btnText={"Log in"}
            loadingState={logInLoadingState}
            buttonClick={logIn}
          />
        </Grid>
        <Grid item xs={12} style={{fontFamily:"Exo2-Bold", display: "grid", justifyItems: "center", alignItems: "center"}}>
          <div className="agslinkText" onClick={() => props.clickForgotPassword()}>Forgot Password</div>
          <div style={{fontSize:"15px"}}>Not a member? <span className='agslinkText' onClick={() => props.clickSignUp()}>Sign up now</span></div>
        </Grid>
      </Grid>
    </div>
  );
}

export default SignIn;