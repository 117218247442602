// import React, { useState, useEffect } from 'react';

import React from 'react';
import "../assets/styles/Buttons.css"
import ButtonLoading from "../assets/images/BtnLoading.gif"

function Buttons(props) {

    return (
        <button 
            className="agsbutton"
            onClick={props.loadingState ? undefined : typeof(props.buttonClick) === "function" ? props.buttonClick : undefined}>
            {props.loadingState ? <img src={ButtonLoading} alt="loading" style={{position: "relative", height: "30px", top: "1px"}}/> : props.btnText}
        </button>
    );
}

export default Buttons;