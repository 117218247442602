import React, { Component } from 'react';
import { Link } from "react-router-dom";

class NotFound extends Component {
  render() {
    return (
      <>
        <h1>Page Not Found</h1>
        <nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
                <Link to="/aboutus">About Us</Link>
              </li>
          </ul>
        </nav>
      </>
    );
  }
}

export default NotFound;
